// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-confissao-belga-js": () => import("./../src/pages/confissao_belga.js" /* webpackChunkName: "component---src-pages-confissao-belga-js" */),
  "component---src-pages-contato-js": () => import("./../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-dort-js": () => import("./../src/pages/dort.js" /* webpackChunkName: "component---src-pages-dort-js" */),
  "component---src-pages-heidelberg-js": () => import("./../src/pages/heidelberg.js" /* webpackChunkName: "component---src-pages-heidelberg-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quemsomos-js": () => import("./../src/pages/quemsomos.js" /* webpackChunkName: "component---src-pages-quemsomos-js" */)
}

